import { Routes, Route } from "react-router-dom";
import { Container, AppLogo } from "./App.styles";
import Home from "./screens/home/home.component";
import { GlobalStyle } from "./global.styles";
import mainLogo from "./assets/business_logo.jpg";

function App() {
  return (
    <Container>
      <GlobalStyle />
      <AppLogo src={mainLogo} alt="Cockatoo Main Logo" />
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </Container>
  );
}

export default App;
