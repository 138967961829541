import coffeeAndBrunchLogo from "../../assets/coffee_and_brunch_1.png";
import pizzaLogo from "../../assets/pizza_1.png";
import cateringLogo from "../../assets/catering.png";

import {
  Container,
  IntroContainer,
  InfoBlurb,
  ContentContainer,
  InnerContainer,
  ALink,
  CardContainer,
  CardImage,
} from "./home.styles";

export const Home = () => {
  return (
    <Container>
      {/*
      <IntroContainer>
        <h2>Hey friend!</h2>
        <InfoBlurb>Please chose which venue to order online from.</InfoBlurb>
        <InfoBlurb>Big ❤️ from The Cockatoo family</InfoBlurb>
      </IntroContainer>
      */}
      <ContentContainer>
        <InnerContainer>
          <ALink href="https://warralily-cockatoo-coffee-brunch.whoods.com.au/">
            <CardContainer>
              <CardImage
                src={coffeeAndBrunchLogo}
                alt="Warralily Cockatoo Coffee &amp; Brunch"
              />
            </CardContainer>
          </ALink>
          <ALink href="https://warralily-cockatoo.whoods.com.au/">
            <CardContainer>
              <CardImage src={pizzaLogo} alt="Warralily Cockatoo Pizza" />
            </CardContainer>
          </ALink>
          <ALink href="https://warralily-cockatoo-catering.whoods.com.au/">
            <CardContainer>
              <CardImage src={cateringLogo} alt="Warralily Cockatoo Catering" />
            </CardContainer>
          </ALink>
        </InnerContainer>
      </ContentContainer>
    </Container>
  );
};

export default Home;
